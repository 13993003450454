<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="dark-light-icon bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>
    <div class="auth-inner py-2">
      <div class="small text-secondary bg-dark-blue text-light border round p-1 shadow mb-2">
        <div align="center"><b>New password must include at least one of each:</b></div>
        <div class="d-flex justify-content-between mt-50">
          <ul class="m-0 px-1">
            <li>Lowercase Character</li>
            <li>Uppercase Character</li>
          </ul>
          <ul class="m-0 px-1">
            <li>Special Character</li>
            <li>Number</li>
          </ul>
        </div>
      </div>
      <!-- Phone Confirmation -->
      <b-card class="mb-0">
        <b-link :to="{ name: 'login' }" class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            FiqOn
          </h2>
          <b-img
            fluid
            class="fiqon-logo" 
            :class="logoUrl ? '' : 'light-logo' "
            :src="logoImg"
            alt="Reset Password"
          />
        </b-link>

        <b-card-title class="mb-1">
          New Password 🔑
        </b-card-title>
        <b-card-text class="mb-2">
          Type your new password, the old one will be overwritten.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="resetPasswordValidation">
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Confirmation Code -->
            <b-form-group
              label="New Password"
              label-for="reset-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
                ref="password"
              >
                <b-form-input
                  id="reset-password"
                  v-model="newPassword"
                  :state="errors.length > 0 ? false:null"
                  name="reset-password"
                  placeholder="- - - - - - "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Confirm New Password"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password confirmation"
                rules="required|confirmed:password"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="newConfirmedPassword"
                  :state="errors.length > 0 ? false:null"
                  name="confirm-password"
                  placeholder="- - - - - - "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            
            <!-- submit button -->
            <b-button
              class="mt-5"
              variant="primary"
              block
              type="submit"
            >
              Confirm
            </b-button>

          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> 
            Back to login
          </b-link>
        </b-card-text>

      </b-card>



    <!-- /Email Confirmation-->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg
} from 'bootstrap-vue'
import { required, email } from '@validations'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'
import Animation from '/src/layouts/components/Animation.vue'
import custom from "@/custom";
import { makeToast } from '@/layouts/components/Popups'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    DarkToggler,
    Animation,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      newPassword: '',
      // validation
      required,
      disabledTemp: false,
      logoImg: require('@/assets/images/logo/logo.svg'),
      newConfirmedPassword: ''
    }
  },
  computed:{
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return false
      }
      return true
    },
  },
  mounted() {
    // this.sendPhoneConfirmation()
  },
  methods: {
    validationForm() {
      this.$refs.resetPasswordValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('resetPassword', { password: this.newPassword, code: this.$route.params.solicitation_code })
            .then(response => {
              if(response){
                makeToast(custom.successMessages.password_reset)
                this.$router.push({ name: 'login' })
              }else{
                alert('Oops, there was an error in vuex')
              }
          }).catch(error => {
            makeToast(error.code ? custom.errorMessages[error.code] : custom.errorMessages.password_reset)
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';

.fiqon-logo-welcome{
  height: 17px;
}
.brand-name{
  color: $primary;
}
</style>